import { useClearRefinements, useCurrentRefinements } from 'react-instantsearch'

import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import { Replay } from '@mui/icons-material'
import type { ButtonProps } from '@mui/material'
import { Button } from '@mui/material'

import { HIERARCHICAL_CATEGORIES } from '@/constants/algolia'
import { useSearchBoxWithInternalState } from '@/providers/c/SearchBoxWithInternalStateProvider'
import { useCategoriesAnalyticsTracking } from '@/utils/frontend/analytics/useCategoriesAnalyticsTracking'
import { useT } from '@/utils/frontend/useT'

type ResetFilterProps = {
  hide?: boolean
} & QaHook &
  ButtonProps

const excludedAttributes = [HIERARCHICAL_CATEGORIES[0]]

export const ResetFilterButton = (props: ResetFilterProps) => {
  const { dataCy, hide = false, ...buttonProps } = props
  const { trackResetFiltersEvent } = useCategoriesAnalyticsTracking()

  const { t } = useT({ keyPrefix: 'catalog.filters' })

  const { refine } = useClearRefinements({
    // we want to clear all the filters except type and family, because it's part of the tree
    excludedAttributes,
  })

  const { clear, value } = useSearchBoxWithInternalState()
  const { items } = useCurrentRefinements({
    excludedAttributes,
  })

  const onClick: Parameters<typeof Button>[0]['onClick'] = (event) => {
    const itemsRefined = items.filter((i) => i.refinements.length)
    const attributes = itemsRefined.map((i) => i.attribute)
    const values = itemsRefined.flatMap((i) => i.refinements.map((r) => String(r.value)))

    refine()
    clear()
    trackResetFiltersEvent(t('reset-all-filters'), attributes, values)
    if (buttonProps?.onClick) buttonProps.onClick(event)
  }

  return (
    <Button
      disabled={!items.length && value.length < 2}
      data-cy={`${dataCy}-button`}
      startIcon={<Replay />}
      sx={[hide ? { display: 'none' } : {}]}
      {...buttonProps}
      onClick={onClick}
    >
      {t('reset-all-filters')}
    </Button>
  )
}
