import type { PropsWithChildren } from 'react'
import type { InstantSearchServerState } from 'react-instantsearch'
import { Configure, InstantSearch, InstantSearchSSRProvider } from 'react-instantsearch'
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs'
import singletonRouter from 'next/router'

import type { DeviceType } from '@knauf-group/ct-shared-nextjs/utils/deviceType'
import type { IndexUiState, UiState } from 'instantsearch.js'
import type { RouterProps } from 'instantsearch.js/es/middlewares'

import {
  algoliaClient,
  createURL,
  generateAlgoliaFilters,
  parseURL,
  routeToState,
  stateToRoute,
} from '@/utils/plain/algolia'
import { generateAlgoliaIndexName } from '@/utils/plain/generateAlgoliaIndexName'
import { isClient } from '@/utils/plain/isClient'
import { splitLocale } from '@/utils/plain/splitLocale'

import { useCategories } from '../c/CategoriesProvider'

export type AlgoliaProviderProps = {
  serverState?: InstantSearchServerState
  serverUrl: string
  locale: string
  deviceType?: DeviceType
  isSSR: boolean
}

export const AlgoliaProvider = (props: PropsWithChildren<AlgoliaProviderProps>) => {
  const { children, serverUrl, serverState, locale, deviceType, isSSR } = props

  const { categoriesMapById } = useCategories()

  const { lang: language, country } = splitLocale(locale)
  const filters = generateAlgoliaFilters({ language })
  const indexName = generateAlgoliaIndexName({
    country,
  })

  const routing: RouterProps<UiState, IndexUiState> = {
    router: createInstantSearchRouterNext({
      singletonRouter,
      serverUrl,
      routerOptions: {
        cleanUrlOnDispose: true,
        // THIS IS CALLED FOURTH
        createURL: createURL({ categoriesMapById, indexName }),
        // THIS IS CALLED FIRST
        parseURL: parseURL({ indexName }),
      },
    }),
    stateMapping: {
      // THIS IS CALLED THIRD
      stateToRoute: stateToRoute({ indexName }),
      // THIS IS CALLED SECOND
      routeToState: routeToState({ indexName }),
    },
  }

  const renderMode = (() => {
    if (isClient) return 'client'
    if (isSSR) return 'ssr'
    return 'isr'
  })()

  return (
    <InstantSearchSSRProvider {...serverState}>
      <InstantSearch
        indexName={indexName}
        searchClient={algoliaClient}
        routing={routing}
        insights
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Configure
          analyticsTags={[
            'reference:pcat',
            `device:${deviceType}`,
            `render-mode:${renderMode}`,
          ]}
          filters={filters}
          hitsPerPage={16}
        />
        {children}
      </InstantSearch>
    </InstantSearchSSRProvider>
  )
}
