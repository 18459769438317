import logger from '@knauf-group/ct-shared-nextjs/logger'

export const extractCategorySlugFromPathname = (pathname: Location['pathname']) => {
  try {
    const pathSegments = pathname.split('/')

    // Find the index of 'p' in the path
    const indexOfP = pathSegments.indexOf('p')

    // Assuming the category slug is the second item after 'p'
    const categorySlug = pathSegments[indexOfP + 2]?.split('?')?.[0]

    // we should also remove .json in case it's cached url
    // where pathname = /_next/data/development/fr-BE/p/produits/plafonds-et-absorbeurs-muraux-35568.json
    if (categorySlug?.endsWith('.json')) {
      return categorySlug.replace('.json', '')
    }

    return categorySlug ?? undefined
  } catch (error) {
    logger.error('getCategorySlugFromHref > Invalid pathname:', error)
    return undefined
  }
}
