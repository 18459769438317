import { Searchbar } from '@knauf-group/ct-designs/components/core/Searchbar'

import { useCategories } from '@/providers/c/CategoriesProvider'
import { useSearchBoxWithInternalState } from '@/providers/c/SearchBoxWithInternalStateProvider'
import { useT } from '@/utils/frontend/useT'

const dataCy = 'Search'

const Search = () => {
  const { clear, onChange, value } = useSearchBoxWithInternalState()

  const { activeCategory, isAllProducts, allProductsCategory } = useCategories()
  const name = isAllProducts ? allProductsCategory.name : activeCategory?.name ?? ''

  const { t } = useT({ keyPrefix: 'common' })
  const searchPlaceholder = isAllProducts
    ? `${t('search-all-products')}`
    : `${t('search-in-category', { categoryName: name })}`

  return (
    <Searchbar
      placeholder={searchPlaceholder}
      value={value}
      onChange={onChange}
      onClear={clear}
      data-cy={`${dataCy}-TextField`}
    />
  )
}

export default Search
