import type { BaseRouter } from 'next/dist/shared/lib/router/router'

import logger from '@knauf-group/ct-shared-nextjs/logger'

// extract locale from pathname, should be the 3nd item when we split by /
// /fr-BE/p/produits/isolation-30870 => produits
// /fr-BE/p/produit/product-id => produit
export const extractPageSlugFromPathname = (asPath: BaseRouter['asPath']) => {
  try {
    return asPath.split('/')[3]
  } catch (error) {
    logger.error('extractPageSlugFromPathname > wrong pathname', error)
    throw error
  }
}
