import type { HierarchicalMenuProps, RefinementListProps } from 'react-instantsearch'
import { useHierarchicalMenu, useRefinementList } from 'react-instantsearch-core'

import { ALGOLIA_FACETS_MAP, HIERARCHICAL_CATEGORIES } from '@/constants/algolia'

const VirtualRefinementList = (props: RefinementListProps) => {
  useRefinementList(props)
  return null
}

const VirtualHierarchicalMenu = (props: HierarchicalMenuProps) => {
  useHierarchicalMenu(props)
  return null
}

type VirtualRefinementsListProps = {
  isAllProductsCategory: boolean
}

// This temporary hack allows to correctly populate the facets during SSR
// If removed useCurrentRefinements state will be inconsistent with the filters in the first render
// Triggering the useRefinementList before using useCurrentRefinements fix this
// see: https://github.com/algolia/instantsearch/issues/5571
export const VirtualRefinementsList = (props: VirtualRefinementsListProps) => {
  const { isAllProductsCategory } = props

  let facetsToDisplay: string[] = Object.keys(ALGOLIA_FACETS_MAP.refinementList)
  if (isAllProductsCategory) {
    facetsToDisplay = Object.entries(ALGOLIA_FACETS_MAP.refinementList)
      .filter(
        ([, facet]) => 'displayOnAllProductsPage' in facet && facet.displayOnAllProductsPage,
      )
      .map(([attribute]) => attribute)
  }

  return (
    <>
      {facetsToDisplay.map((attribute) => (
        <VirtualRefinementList key={attribute} attribute={attribute} />
      ))}
      <VirtualHierarchicalMenu attributes={HIERARCHICAL_CATEGORIES} limit={99} />
    </>
  )
}
