import NextLink from 'next/link'

import Breadcrumbs from '@knauf-group/ct-designs/components/core/Breadcrumbs/Breadcrumbs'

import { useBreadcrumbsLinks } from '@/components/Breadcrumbs'
import { useCategories } from '@/providers/c/CategoriesProvider'
import { useT } from '@/utils/frontend/useT'

export const CategoriesBreadcrumbs = () => {
  const { activeCategory, isAllProducts, allProductsCategory } = useCategories()
  const name = isAllProducts ? allProductsCategory.name : activeCategory?.name ?? ''

  const { t } = useT({ keyPrefix: 'common.breadcrumbs' })

  const breadcrumbsLinks = useBreadcrumbsLinks({ categoryName: name, page: 'category' })

  return (
    <Breadcrumbs
      links={breadcrumbsLinks}
      nextLinkComponent={NextLink}
      backLabel={t('back-to-all-products')}
    />
  )
}
