import { grey } from '@knauf-group/ct-designs/themeConfigs/rebranding/colors'
import type { Theme } from '@mui/material'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
} from '@mui/material'
import type { SxProps, SystemStyleObject } from '@mui/system'

import { generateLineClamp } from '@/utils/frontend/generateLineClamp'
import { generateImageAlt } from '@/utils/plain/generateImageAlt'

import { DIYLabel } from '../DIYLabel'
import { KnaufImage } from '../KnaufImage'
import Link from '../Link'

type ProductCardProps = {
  title: string
  description: string
  image: string
  href: string
  isDIY?: boolean
  dataCy?: string
  onClick?: () => void
  priorityImageLoading?: boolean
}

// NOTE: the product card will have an inline style, because the global theme will be extracted to a separate module
// and we don't want to force all the cards to have the same styles as the product card

// TODO: force styles, because product dialect typography is not available for web dialect
const styles = {
  card: {
    height: '100%',
    background: (theme) => theme.palette.background.grey,
    boxShadow: (theme) => `0 0 0 1px ${theme.palette.divider} inset`,
  } as SxProps<Theme>,
  cardActionArea: {
    height: '100%',
  } as SxProps<Theme>,
  cardMedia: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
    overflow: 'hidden',
    bgcolor: 'background.default',
    boxShadow: (theme) => `0 -1px 0px 0px ${theme.palette.divider} inset`,
    m: '1px',
    pb: '1px',
  } as SxProps<Theme>,
  cardContent: {
    height: '100%',
  } as SystemStyleObject<Theme>,
  DIYLabelContainer: {
    backgroundColor: grey[600],
    marginTop: '-1px',
    marginX: '1px',
    justifyContent: 'center',
  } as SxProps<Theme>,
  DIYLabel: {
    color: 'primary.contrastText',
    textAlign: 'center',
  } as SxProps<Theme>,
}

const LINES_TO_SHOW_TITLE = 2
const LINES_TO_SHOW_DESCRIPTION = 2
const dataCyPrefix = 'ProductCard'
// 257 is the max width value that the image can have with the max screen size for container XL
const MAX_IMAGE_WIDTH = 257

export const ProductCard = (props: ProductCardProps) => {
  const {
    title,
    description,
    image,
    href,
    isDIY = false,
    dataCy,
    onClick,
    priorityImageLoading = false,
  } = props

  const cDataCy = `${dataCyPrefix}-${dataCy}`
  const imageAlt = generateImageAlt({ productName: title })

  return (
    <Card data-cy={cDataCy} sx={styles.card}>
      <CardActionArea
        LinkComponent={Link}
        component={Link}
        href={href}
        onClick={onClick}
        prefetch={false}
        sx={styles.cardActionArea}
      >
        <CardMedia sx={styles.cardMedia} data-cy={`${cDataCy}-CardMedia`}>
          <KnaufImage
            alt={imageAlt}
            src={image}
            // becuase we want the aspect ratio to be 1:1, we set the height to the same value as the width
            width={MAX_IMAGE_WIDTH}
            height={MAX_IMAGE_WIDTH}
            priority={priorityImageLoading}
            style={{
              width: '100%',
              height: 'auto',
              aspectRatio: 1,
            }}
          />
        </CardMedia>

        {isDIY && (
          <DIYLabel
            boxSx={styles.DIYLabelContainer}
            typographySx={styles.DIYLabel}
            dataCy={cDataCy}
          />
        )}

        <CardContent sx={styles.cardContent}>
          <Tooltip title={title}>
            <Typography
              component="h3"
              variant="body2bold"
              sx={generateLineClamp(LINES_TO_SHOW_TITLE)}
              gutterBottom
              data-cy={`${cDataCy}-title`}
            >
              {title}
            </Typography>
          </Tooltip>
          <Typography
            component="p"
            variant="body3dense"
            sx={generateLineClamp(LINES_TO_SHOW_DESCRIPTION)}
            color="text.secondary"
            data-cy={`${cDataCy}-description`}
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
