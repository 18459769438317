import { useRouter } from 'next/router'

import { AlgoliaProvider } from '@/providers/algolia/AlgoliaProvider'
import type { AllProductsCategory } from '@/providers/c/CategoriesProvider'
import { ALL_PRODUCTS_CATEGORY_ID, CategoriesProvider } from '@/providers/c/CategoriesProvider'
import { RenderKeyProvider } from '@/providers/c/RenderKeyProvider'
import { SearchBoxWithInternalStateProvider } from '@/providers/c/SearchBoxWithInternalStateProvider'
import CategoryTemplate from '@/templates/CategoriesPage'
import type { CategoriesNextPageNoErrorProps } from '@/types'
import { useT } from '@/utils/frontend/useT'
import { extractIdFromSlug } from '@/utils/plain/extractIdFromSlug'
import { extractCategorySlugFromPathname } from '@/utils/plain/getCategorySlug'
import { isClient } from '@/utils/plain/isClient'
import { isNotEmpty } from '@/utils/plain/isEmpty'

import { VirtualRefinementsList } from './algolia/VirtualRefinements'

// because we use the next.config rewrite, the server useRouter and client useRouter are different
// on server asPath it like your folder path, which for our rewrites is pages/internal/isr/pcat/[path]/[[...slug]]
// that's why to get the category slug we apply different logic
const useExtractCategorySlugFromRouter = () => {
  const router = useRouter()

  // on client we extract slug from the location.pathname (asPath in nextjs)
  if (isClient) {
    return extractCategorySlugFromPathname(router.asPath)
  }

  // on server, we extract it from the nextjs router queries
  const categorySlug = (router.query?.slug as string[])?.[0]
  return categorySlug
}

const CategoriesPage = (props: CategoriesNextPageNoErrorProps) => {
  const { categories, algoliaProviderProps } = props
  const { t } = useT({ keyPrefix: 'common' })

  const categorySlug = useExtractCategorySlugFromRouter()

  // on this page we know that the slug is not empty and valid, otherwise we would get 404 error page
  const categoryId = isNotEmpty(categorySlug) ? extractIdFromSlug(categorySlug) : undefined

  const allProductsCategory: AllProductsCategory = {
    id: ALL_PRODUCTS_CATEGORY_ID,
    order: 0,
    name: t('all-products-category'),
  }

  const isAllProductsCategory = categoryId == null

  return (
    <CategoriesProvider
      categories={categories}
      activeCategoryId={categoryId}
      allProductsCategory={allProductsCategory}
    >
      <AlgoliaProvider {...algoliaProviderProps}>
        <VirtualRefinementsList isAllProductsCategory={isAllProductsCategory} />
        <SearchBoxWithInternalStateProvider>
          <RenderKeyProvider>
            <CategoryTemplate />
          </RenderKeyProvider>
        </SearchBoxWithInternalStateProvider>
      </AlgoliaProvider>
    </CategoriesProvider>
  )
}

export default CategoriesPage
