import type { PropsWithChildren } from 'react'
import { createContext, useContext, useState } from 'react'

import { useDeepCompareMemo } from 'use-deep-compare'

type RenderKeyContextType = {
  renderKey: number
  forceNewRenderForFilterChips: () => void
}

const initialState: RenderKeyContextType = {
  renderKey: 0,
  forceNewRenderForFilterChips: () => {},
}

export const RenderKeyContext = createContext(initialState)
export const useRenderKey = () => useContext(RenderKeyContext)

/**
 * @description this provider is used to do a forced rerender for the MultiselectFilterChips components
 * to reset the internal state, when we click the Global Reset Button or when we switch between categories
 */
export const RenderKeyProvider = (props: PropsWithChildren<{}>) => {
  const { children } = props

  const [renderKey, setRenderKey] = useState(0)

  const forceNewRenderForFilterChips = () => {
    setRenderKey((key) => key + 1)
  }

  const memoValue = useDeepCompareMemo(
    () => ({
      renderKey,
      forceNewRenderForFilterChips,
    }),
    [renderKey, forceNewRenderForFilterChips],
  )

  return <RenderKeyContext.Provider value={memoValue}>{children}</RenderKeyContext.Provider>
}
