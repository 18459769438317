import env from '@beam-australia/react-env'
import logger from '@knauf-group/ct-shared-nextjs/logger'
import algoliasearch from 'algoliasearch'
import type { StateMapping } from 'instantsearch.js'
import type { BrowserHistoryArgs } from 'instantsearch.js/es/lib/routers/history'
import type { IndexUiState, UiState } from 'instantsearch.js/es/types/ui-state'
import { pickBy } from 'lodash'

import type { QueryParams } from '@/constants/algolia'
import {
  ALGOLIA_FACETS_MAP,
  ALGOLIA_NAME_ASC_SORT_INDEX_SUFFIX,
  ALGOLIA_NAME_DESC_SORT_INDEX_SUFFIX,
  HIERARCHICAL_CATEGORIES,
} from '@/constants/algolia'
import type { Category } from '@/types'

import { extractIdFromSlug } from './extractIdFromSlug'
import { extractLocaleFromPathname } from './extractLocaleFromPathname'
import { extractPageSlugFromPathname } from './extractPageSlugFromPathname'
import { extractCategorySlugFromPathname } from './getCategorySlug'
import { getPageType } from './getPageType'
import { isNotEmpty } from './isEmpty'
import { nonNullable } from './nonNullable'
import { getCategoryPagePath, getProductDetailsPath } from './PathGenerator'
import { removeDoubleSlashes } from './removeDoubleSlashes'

export const algoliaClient = algoliasearch(env('ALGOLIA_APP_ID'), env('ALGOLIA_SEARCH_KEY'))

// To query Algolia with params
export const generateAlgoliaFilters = (props: { language: string }) => {
  const { language } = props
  return `language:${language}`
}

// extract product slug from pathname, should be the 4nd item when we split by /
// /fr-BE/p/produit/product-id => product-id
const extractProductSlugFromPathname = (pathname: Location['pathname']) => {
  try {
    return pathname.split('/')[4]
  } catch (error) {
    logger.error('extractPageSlugFromPathname > wrong pathname', error)
    throw error
  }
}

export const generateQueryParamsFromIndexUiState = (args: {
  indexUiState: IndexUiState
  indexName: string
}) => {
  const { indexUiState, indexName } = args

  // key is UrlParam, value is indexUiState facet value
  const refinementListQueryParams = Object.entries(ALGOLIA_FACETS_MAP.refinementList).reduce(
    (acum, [facet, facetProps]) => {
      const key = facetProps.urlParam
      const value = indexUiState?.refinementList?.[facet]

      return {
        ...acum,
        [key]: value,
      }
    },
    {} as Exclude<QueryParams, 'product-type' | 'product-family' | 'search' | 'order'>,
  )

  const queryParameters: QueryParams = {
    // hierarchicalMenu, search and order are special cases that we can hardcode here
    'product-type': indexUiState?.hierarchicalMenu?.[HIERARCHICAL_CATEGORIES[0]]?.[1],
    'product-family': indexUiState?.hierarchicalMenu?.[HIERARCHICAL_CATEGORIES[0]]?.[2],

    // the rest of the filters are from refinementList, so we can map them
    ...refinementListQueryParams,

    // search and order as last items
    search: indexUiState?.query,
    order: indexUiState?.sortBy?.replace(`${indexName}_`, ''),
  }

  return pickBy(queryParameters, isNotEmpty)
}

// `qs` does not return an array when there's a single value.
const normalizeMultiselectQueryParam = (queryParam?: string | string[]) => {
  if (queryParam == null) return []
  if (Array.isArray(queryParam)) return queryParam
  return [queryParam].filter(nonNullable)
}

export const generateIndexUiStateFromQueryParams = (args: {
  queryParams: QueryParams
  indexName: string
  categoryId?: string | null
}) => {
  const { queryParams, indexName, categoryId } = args

  // 'name_asc' => 'dev_products_be_name_asc'
  // undefined => undefined
  // 'name_ascc' => undefined because is not valid value
  const sortBy = (() => {
    if (queryParams.order == null) return undefined

    const isValidSortBy = [
      ALGOLIA_NAME_ASC_SORT_INDEX_SUFFIX,
      ALGOLIA_NAME_DESC_SORT_INDEX_SUFFIX,
    ].includes(queryParams.order)

    if (!isValidSortBy) return undefined

    return `${indexName}_${queryParams.order}`
  })()

  const hierarchicalMenu = {
    [HIERARCHICAL_CATEGORIES[0]]: ((): string[] => {
      // is there is no category, query params for family and type have no sense
      if (categoryId == null) return []

      // is there is no type but family is presented, that has no sense, so, just use the category
      if (isNotEmpty(queryParams['product-family']) && queryParams['product-type'] == null)
        return [categoryId]

      // good case
      return [categoryId, queryParams['product-type'], queryParams['product-family']].filter(
        isNotEmpty,
      ) as string[]
    })(),
  }

  // key is facet, value is normalized multiselect query param
  const refinementList = Object.entries(ALGOLIA_FACETS_MAP.refinementList).reduce(
    (acum, [facet, facetProps]) => {
      const key = facet
      const value = normalizeMultiselectQueryParam(queryParams[facetProps.urlParam])

      return {
        ...acum,
        [key]: value,
      }
    },
    {} as { [attribute: string]: string[] },
  )

  const indexUiState: IndexUiState = {
    query: queryParams.search,
    sortBy,
    refinementList: pickBy(refinementList, isNotEmpty),
    hierarchicalMenu: pickBy(hierarchicalMenu, isNotEmpty),
  }

  return pickBy(indexUiState, isNotEmpty)
}

const generateUrl = (props: {
  protocol: string
  hostname: string
  pathname: string
  port?: string
  path: string
  hash: string
}) => {
  const { protocol, hostname, pathname, port = '', path, hash } = props
  const portWithPrefix = port === '' ? '' : `:${port}`
  const urlLocale = extractLocaleFromPathname(pathname)

  return removeDoubleSlashes(
    `${protocol}//${hostname}${portWithPrefix}/${urlLocale}/${path}${hash}`,
  )
}

// THIS IS CALLED FOURTH
export const createURL =
  (props: {
    categoriesMapById: Record<string, Category>
    indexName: string
  }): BrowserHistoryArgs<IndexUiState>['createURL'] =>
  (args): string => {
    const { categoriesMapById, indexName } = props
    const { location, qsModule, routeState: indexUiState } = args
    const { protocol, hostname, port, hash, pathname } = location

    // if location.pathname is a product page, not category page, we should return pathname as it is
    // pathname can be /nl-BE/p/producten or /nl-BE/p/product/mp-75-10256_0217
    const locale = extractLocaleFromPathname(pathname)
    const pageSlug = extractPageSlugFromPathname(pathname)

    const pageType = getPageType(pageSlug, locale)

    if (pageType === 'product') {
      const productSlug = extractProductSlugFromPathname(pathname)
      const productPagePath = getProductDetailsPath({ locale, productSlug })
      const url = generateUrl({
        hash,
        hostname,
        path: productPagePath,
        pathname,
        protocol,
        port,
      })
      return url
    }

    const hierarchicalCategories = indexUiState?.hierarchicalMenu?.[HIERARCHICAL_CATEGORIES[0]]

    const categoryId = hierarchicalCategories?.[0]
    const categorySlug = isNotEmpty(categoryId)
      ? categoriesMapById[categoryId]?.slug
      : undefined

    const queryParameters = generateQueryParamsFromIndexUiState({
      indexUiState,
      indexName,
    })

    const search = qsModule.stringify(queryParameters, {
      addQueryPrefix: true,
    })

    // https://github.com/algolia/instantsearch/blob/a8943896a19c57fabf54d4b8fea495c57fe6846e/packages/instantsearch.js/src/lib/routers/history.ts#L285
    const categoryPagePath = getCategoryPagePath({
      locale,
      categorySlug,
      search,
    })

    const url = generateUrl({
      hash,
      hostname,
      path: categoryPagePath,
      pathname,
      protocol,
      port,
    })

    return url
  }

// THIS IS CALLED FIRST
export const parseURL =
  (props: { indexName: string }): BrowserHistoryArgs<IndexUiState>['parseURL'] =>
  (args) => {
    const { indexName } = props
    const { location, qsModule } = args
    const { pathname, search } = location
    const categorySlug = extractCategorySlugFromPathname(pathname)

    const categoryId = isNotEmpty(categorySlug) ? extractIdFromSlug(categorySlug) : undefined

    const queryParams = qsModule.parse(
      search.slice(1), // slice(1) removes the leading '?'
      {
        arrayLimit: 99,
      },
    ) as QueryParams

    const indexUiState = generateIndexUiStateFromQueryParams({
      queryParams,
      indexName,
      categoryId,
    })

    return indexUiState
  }

// THIS IS CALLED THIRD
export const stateToRoute =
  (props: { indexName: string }): StateMapping<UiState, IndexUiState>['stateToRoute'] =>
  (uiState = {}) => {
    const { indexName } = props
    const routeState = pickBy(uiState[indexName] ?? {}, isNotEmpty)
    return routeState
  }

// THIS IS CALLED SECOND
export const routeToState =
  (props: { indexName: string }): StateMapping<UiState, IndexUiState>['routeToState'] =>
  (indexUiState: IndexUiState = {}) => {
    const { indexName } = props
    const uiState = {
      [indexName]: indexUiState,
    }
    return uiState
  }
