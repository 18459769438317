import dynamic from 'next/dynamic'

import { Box, Stack, Typography } from '@mui/material'

import { useCategories } from '@/providers/c/CategoriesProvider'
import { useBreakpoints } from '@/utils/frontend/useBreakpoints'

import type { SortByDropdown } from '../algolia/SortBy'
import type { SidebarFiltersDrawer } from '../SidebarFiltersDrawer'

const DynamicSiderbarFiltersDrawer = dynamic<React.ComponentProps<typeof SidebarFiltersDrawer>>(
  () => import('../SidebarFiltersDrawer').then((mod) => mod.SidebarFiltersDrawer),
)

const DynamicSortByDropdown = dynamic<React.ComponentProps<typeof SortByDropdown>>(() =>
  import('../algolia/SortBy').then((mod) => mod.SortByDropdown),
)

const dataCy = 'TitleWithMobileSidebar'

export const TitleWithMobileSidebar = () => {
  const { activeCategory, isAllProducts, allProductsCategory } = useCategories()
  const name = isAllProducts ? allProductsCategory.name : activeCategory?.name ?? ''
  const { isDesktop, isNotDesktop } = useBreakpoints()

  return (
    <Stack
      direction={isDesktop ? 'row' : 'column'}
      justifyContent="space-between"
      alignItems={isDesktop ? 'center' : 'flex-start'}
      spacing={2}
    >
      <Typography
        component="h1"
        variant={isDesktop ? 'h4' : 'h5'}
        data-cy={`${dataCy}-title`}
        sx={[{ wordBreak: 'break-word', hyphens: 'auto' }]}
      >
        {name}
      </Typography>

      {isDesktop && (
        <Box sx={{ display: 'flex' }}>
          <DynamicSortByDropdown dataCyPrefix="desktop" />
        </Box>
      )}

      {isNotDesktop && <DynamicSiderbarFiltersDrawer />}
    </Stack>
  )
}
