import { useInfiniteHits } from 'react-instantsearch'

import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import { Box, Button, Grid, Typography } from '@mui/material'

import { NoResults } from '@/components/CategoriesPage/NoResults'
import { ProductCard } from '@/components/ProductCard'
import { useCategoriesAnalyticsTracking } from '@/utils/frontend/analytics/useCategoriesAnalyticsTracking'
import { useBreakpoints } from '@/utils/frontend/useBreakpoints'
import { useLocalePathGenerator } from '@/utils/frontend/usePathGenerator'
import { useT } from '@/utils/frontend/useT'

import { transformProductItems } from './transformItems'

const dataCy = 'ProductHits'

export const ProductHits = () => {
  const { t } = useT()
  const { trackClickEvent } = useCategoriesAnalyticsTracking()
  const { getProductDetailsPath } = useLocalePathGenerator()
  const { items, showMore, results, isLastPage, sendEvent } = useInfiniteHits({
    transformItems: transformProductItems,
  })
  const { nbHits } = results ?? {}

  const hasHits = Boolean(nbHits && nbHits > 0)

  const onClickShowMore = () => {
    trackClickEvent(t('catalog.show-more'), 'ProductHits-showMore', 'internal_cta_click')
    showMore()
  }

  const { isDesktop } = useBreakpoints()

  return (
    <Box>
      <Grid
        container
        spacing={2}
        data-cy={`${dataCy}-Grid`}
        sx={[!hasHits && { display: 'none' }]}
      >
        {items.map((item, index) => (
          <Grid
            item
            xs={6}
            sm={4}
            lg={3}
            key={item.objectID}
            data-cy={`${dataCy}-GridItem-${item.objectID}`}
          >
            <ProductCard
              title={item.name}
              description={item.shortDescription}
              image={item.image}
              href={getProductDetailsPath(item.slug)}
              isDIY={item.isDIY}
              dataCy={item.objectID}
              onClick={() => {
                sendEvent('click', item, 'Product Clicked')
                trackClickEvent(
                  item.name,
                  getProductDetailsPath(item.slug),
                  'internal_link_click',
                )
              }}
              priorityImageLoading={index < (isDesktop ? 12 : 4)}
            />
          </Grid>
        ))}
        <Grid item xs={12} textAlign="center">
          <Typography gutterBottom variant="body3" component="p" data-cy={`${dataCy}-count`}>
            <Typography variant="body3bold" data-cy={`${dataCy}-count-current`}>
              {items.length}
            </Typography>
            {` ${t('common.from')} ${t(
              // TODO i18next interpret plurals for pl weirdly
              nbHits === 1 ? 'catalog.items_one' : 'catalog.items_other',
              { count: nbHits },
            )}`}
          </Typography>
          <Button
            variant="outlined"
            startIcon={<ExpandMoreOutlinedIcon />}
            color="primary"
            size="medium"
            onClick={onClickShowMore}
            disabled={isLastPage}
            data-cy={`${dataCy}-showMore`}
          >
            {t('catalog.show-more')}
          </Button>
        </Grid>
      </Grid>
      {hasHits && (
        <div
          id="qualtrics-inline-feedback"
          style={{
            margin: '0px',
            textAlign: 'center',
          }}
        />
      )}
      <NoResults hide={hasHits} />
    </Box>
  )
}
