import type { BaseRouter } from 'next/dist/shared/lib/router/router'

import logger from '@knauf-group/ct-shared-nextjs/logger'

// extract locale from pathname, should be the 1nd item when we split by /
// /fr-BE/p/produits/isolation-30870 => fr-BE
export const extractLocaleFromPathname = (asPath: BaseRouter['asPath']) => {
  try {
    return asPath.split('/')[1]
  } catch (error) {
    logger.error('extractLocaleFromLocation > wrong pathname', error)
    throw error
  }
}
