import logger from '@knauf-group/ct-shared-nextjs/logger'

import {
  defaultValues,
  useCatalogCustomPropertiesFromAlgolia,
  useFiltersCustomProperties,
} from './propertiesHelpers'
import { useInitializeAnalytics } from './useInitializeAnalytics'

export const useCategoriesAnalyticsTracking = () => {
  const { analytics, isReady } = useInitializeAnalytics()
  const catalogPageCustomProperties = useCatalogCustomPropertiesFromAlgolia()
  const filterCustomProperties = useFiltersCustomProperties()

  if (!isReady) {
    const warnFn = () =>
      logger.warn('Trigger analytics tracking before initialization is ready')

    return {
      trackFilterEvent: warnFn,
      trackResetFiltersEvent: warnFn,
      trackClickEvent: warnFn,
    }
  }

  const trackFilterEvent = (filterName: string, filterValues: string[]) => {
    analytics.track('filter', {
      ...defaultValues,
      ...catalogPageCustomProperties,
      filter_name: filterName,
      filter_value: filterValues.join(';'),
    })
  }

  const trackResetFiltersEvent = (
    action: string,
    filterNames: string[],
    filterValues: string[],
  ) => {
    analytics.track('reset', {
      ...defaultValues,
      ...catalogPageCustomProperties,
      action,
      filter_name: filterNames.join(';'),
      filter_value: filterValues.join(';'),
    })
  }

  const trackClickEvent = (
    action: string,
    target: string,
    eventSpecification: 'internal_cta_click' | 'internal_link_click',
  ) => {
    analytics.track('click', {
      ...defaultValues,
      ...catalogPageCustomProperties,
      ...filterCustomProperties,
      action,
      target,
      event_specification: eventSpecification,
    })
  }

  return { trackFilterEvent, trackResetFiltersEvent, trackClickEvent }
}
