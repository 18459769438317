import type { UseHitsProps } from 'react-instantsearch'

import type { Hit } from 'instantsearch.js'

import type { ProductFromAlgolia } from '@/types'
import { getStaticAssetPath } from '@/utils/plain/getStaticAssetPath'
import { isEmpty } from '@/utils/plain/isEmpty'

const DEFAULT_IMAGE = getStaticAssetPath('/img/empty-product-image.png')

export const transformProductItems: UseHitsProps<Hit<ProductFromAlgolia>>['transformItems'] = (
  items,
) => {
  return items?.map((item) => ({
    ...item,
    image: isEmpty(item.image) ? DEFAULT_IMAGE : item.image,
  }))
}
