import { renderToString } from 'react-dom/server'
import { getServerState } from 'react-instantsearch-core'
import type { GetServerSideProps, NextPage } from 'next'
import Head from 'next/head'
import type { Locale } from 'next/router'

import type { IAppsConfig } from '@knauf-group/ct-shared-nextjs/client'
import logger from '@knauf-group/ct-shared-nextjs/logger'
import type { DeviceType } from '@knauf-group/ct-shared-nextjs/utils/deviceType'
import { inferDeviceType } from '@knauf-group/ct-shared-nextjs/utils/deviceType'
import { Box } from '@mui/material'

import CategoriesPage from '@/components/CategoriesPage/Page'
import CategoriesPageLite from '@/components/CategoriesPage/PageLite'
import { PageLayout } from '@/layouts/PageLayout'
import { CategoryService } from '@/services/category.service'
import type { CategoriesNextPageProps } from '@/types'
import { generateServerUrlFromContext } from '@/utils/backend/generateServerUrlFromContext'
import { getCanonicalHref } from '@/utils/backend/getCanonicalHref'
import { getCommonServerProps } from '@/utils/backend/getCommonServerProps'
import { extractIdFromSlug } from '@/utils/plain/extractIdFromSlug'
import { isEmpty, isNotEmpty } from '@/utils/plain/isEmpty'

type PageProps = CategoriesNextPageProps & {
  appsConfig: IAppsConfig
}

export const PCAT: NextPage<PageProps> = (props) => {
  const {
    canonicalHref,
    footerEntries,
    headerEntries,
    appsConfig,
    algoliaProviderProps,
    categories,
  } = props

  return (
    <Box>
      <Head>
        <link rel="canonical" href={canonicalHref} />
      </Head>
      <PageLayout
        footerEntries={footerEntries}
        headerEntries={headerEntries}
        appsConfig={appsConfig}
      >
        <CategoriesPage algoliaProviderProps={algoliaProviderProps} categories={categories} />
      </PageLayout>
    </Box>
  )
}

// we reuse this function inside ISR page, so, make sure not ctx.req is used here
export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const isSSR = 'req' in ctx
  const deviceType: DeviceType | undefined =
    isSSR && ctx.req.headers['user-agent']
      ? inferDeviceType(ctx.req.headers['user-agent'])
      : undefined

  logger.debug(`${isSSR ? 'SSR' : 'ISR'} is called`)

  const locale = ctx.locale as Locale

  const categoryPath = ctx.params?.path as string
  const categorySlug = (ctx.params?.slug as string[])?.[0]

  // categories, if it fails, we still will get an empty array for categories
  const { categories } = await CategoryService.getCategories(locale)

  const categoryId = isNotEmpty(categorySlug) ? extractIdFromSlug(categorySlug) : undefined
  const activeCategory = isNotEmpty(categoryId)
    ? categories.find((category) => category.id === categoryId)
    : undefined

  // return not found is the slug exists but the category is not found
  if (isNotEmpty(categorySlug) && isEmpty(activeCategory)) {
    logger.info(`Category slug not found`, { categorySlug, locale })

    return {
      // "category-not-found" error
      notFound: true,
    }
  }

  // algolia ssr
  const serverUrl = generateServerUrlFromContext(ctx)

  const props = {
    key: categoryId ?? 'all',
    categories,
    algoliaProviderProps: {
      serverUrl,
      locale,
      ...(deviceType && { deviceType }),
      isSSR,
    },
  }

  // we prerender the lite version of the categories page to get the server state of algolia
  const serverState = await getServerState(
    <CategoriesPageLite
      key={props.key}
      algoliaProviderProps={props.algoliaProviderProps}
      categories={props.categories}
      activeCategory={activeCategory}
    />,
    {
      renderToString,
    },
  )

  const canonicalHref = getCanonicalHref(locale, categoryPath, activeCategory?.slug)

  return {
    props: {
      ...props,
      algoliaProviderProps: {
        ...props.algoliaProviderProps,
        serverState,
      },
      ...(await getCommonServerProps({ locale, canonicalHrefPath: canonicalHref })),
      canonicalHref,
    },
  }
}

export default PCAT
