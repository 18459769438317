import dynamic from 'next/dynamic'
import { NextSeo } from 'next-seo'

import { Box, Container, Grid, Stack } from '@mui/material'

import type { ActiveFiltersChips } from '@/components/CategoriesPage/algolia/ActiveFiltersChips'
import { ProductHits } from '@/components/CategoriesPage/algolia/ProductHits'
import Search from '@/components/CategoriesPage/algolia/Search'
import { CategoriesBreadcrumbs } from '@/components/CategoriesPage/CategoriesBreadcrumbs'
import type DesktopFacets from '@/components/CategoriesPage/DesktopFacets'
import type { Sidebar } from '@/components/CategoriesPage/Sidebar'
import { TitleWithMobileSidebar } from '@/components/CategoriesPage/TitleWithMobileSidebar'
import { useCategories } from '@/providers/c/CategoriesProvider'
import { useCatalogCustomPropertiesFromAlgolia } from '@/utils/frontend/analytics/propertiesHelpers'
import { useCategoriesPageView } from '@/utils/frontend/analytics/usePageView'
import { useTrackScrollPosition } from '@/utils/frontend/analytics/useTrackScrollPosition'
import { useBreakpoints } from '@/utils/frontend/useBreakpoints'

const DynamicDesktopFacets = dynamic<React.ComponentProps<typeof DesktopFacets>>(
  () => import('@/components/CategoriesPage/DesktopFacets'),
)

const DynamicActiveFiltersChips = dynamic<React.ComponentProps<typeof ActiveFiltersChips>>(() =>
  import('@/components/CategoriesPage/algolia/ActiveFiltersChips').then(
    (mod) => mod.ActiveFiltersChips,
  ),
)

const DynamicSidebar = dynamic<React.ComponentProps<typeof Sidebar>>(() =>
  import('@/components/CategoriesPage/Sidebar').then((mod) => mod.Sidebar),
)

const CategoryTemplate = () => {
  const { activeCategory, allProductsCategory, isAllProducts } = useCategories()
  const name = isAllProducts ? allProductsCategory.name : activeCategory?.name ?? ''

  const { isDesktop } = useBreakpoints()

  // Require Algolia Provider to get the selected product type
  useCategoriesPageView()
  useTrackScrollPosition(useCatalogCustomPropertiesFromAlgolia())

  return (
    <Box flex="1">
      <NextSeo title={name} />
      <Container maxWidth={false} disableGutters>
        <Container component="main" maxWidth="xl">
          <Grid container columnSpacing={5} sx={{ pb: 5 }}>
            <Grid item xs={12}>
              <CategoriesBreadcrumbs />
            </Grid>

            {isDesktop && (
              <Grid
                item
                xs="auto" // auto because the Sidebar has fixed width
                sx={{
                  display: {
                    xs: 'none',
                    lg: 'block',
                  },
                }}
              >
                <DynamicSidebar />
              </Grid>
            )}

            <Grid item xs>
              <Stack spacing={2}>
                <Stack spacing={2} direction={{ xs: 'column-reverse' }}>
                  {/* Searchbar */}
                  <Search />
                  {/* Category title + sort */}
                  <TitleWithMobileSidebar />
                </Stack>
                {/* New Filter Design */}
                {isDesktop && <DynamicDesktopFacets />}
                {/* Active Filters chips */}
                {isDesktop && <DynamicActiveFiltersChips />}
                {/* Results */}
                <Box>
                  <ProductHits />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Box>
  )
}

export default CategoryTemplate
