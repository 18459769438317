import { useClearRefinements } from 'react-instantsearch-core'
import Image from 'next/image'

import { ArrowBackIosNew } from '@mui/icons-material'
import { Button, Grid, Typography } from '@mui/material'

import { ResetFilterButton } from '@/components/CategoriesPage/algolia/ResetFiltersButton'
import Link from '@/components/Link'
import { SEARCH_QUERY_KEY } from '@/constants/algolia'
import { useCategories } from '@/providers/c/CategoriesProvider'
import { useSearchBoxWithInternalState } from '@/providers/c/SearchBoxWithInternalStateProvider'
import { useBreakpoints } from '@/utils/frontend/useBreakpoints'
import { useLocalePathGenerator } from '@/utils/frontend/usePathGenerator'
import { useT } from '@/utils/frontend/useT'
import { getStaticAssetPath } from '@/utils/plain/getStaticAssetPath'

type NoResultsProps = {
  hide?: boolean
}

const knaufWorkerBrandedSvgPath = getStaticAssetPath(`/svg/knauf-worker-branded.svg`)

export const NoResults = (props: NoResultsProps) => {
  const { hide = false } = props
  const { isAllProducts } = useCategories()
  const { refine: clearAllRefinements } = useClearRefinements()
  const { value } = useSearchBoxWithInternalState()
  const { getCategoryPagePath } = useLocalePathGenerator()
  const { isDesktop } = useBreakpoints()

  const { t } = useT({ keyPrefix: 'catalog' })

  const showSearchInAllProducts = Boolean(!isAllProducts && value && value.length > 0)
  const allProductsHref = `${getCategoryPagePath()}?${SEARCH_QUERY_KEY}=${value}`

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={2}
      sx={[hide && { display: 'none' }]}
    >
      <Grid item>
        <Typography variant="h2" align="center">
          {t('no-results-title').toUpperCase()}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" align="center" color="text.secondary">
          {t('no-results-description')}
        </Typography>
        {!hide && (
          <div
            id="qualtrics-inline-feedback"
            style={{
              margin: '0px',
              textAlign: 'center',
            }}
          />
        )}
      </Grid>
      <Grid item>
        {showSearchInAllProducts && (
          <Button
            LinkComponent={Link}
            component={Link}
            prefetch={false}
            shallow
            data-cy="search-in-all-products"
            href={allProductsHref}
            startIcon={<ArrowBackIosNew />}
            onClick={clearAllRefinements}
          >
            {t('filters.search-in-all-products')}
          </Button>
        )}
        <ResetFilterButton
          dataCy="no-results-action"
          hide={showSearchInAllProducts}
          variant="contained"
        />
      </Grid>

      <Grid item>
        <Image
          src={knaufWorkerBrandedSvgPath}
          alt="no results"
          width={isDesktop ? 390 : 164}
          height={isDesktop ? 390 : 164}
          data-cy="no-results-image"
        />
      </Grid>
    </Grid>
  )
}
